import React, { useState, useEffect, useContext } from 'react'
import filterIcon from '../../../assets/img/filter.png'
import Filter from '../../Shared/Filter'
import 'react-datepicker/dist/react-datepicker.css'
import BillingTabSearch from '../Shared/BillingTabSearch'
import {
  getOtherInstallations
} from '../../../services/api/billingService'
import { AuditMonthContext } from '../../../context/AuditMonthContext'
import { NotifierContext } from '../../../context/NotifierContext'
import NoDataFound from '../../../components/atoms/NoDataFound'
import Paginator from '../../../components/molecules/Paginator'
import Ellipsis from '../../../components/molecules/Ellipsis'
import { perPageCount } from '../../../assets/offlineData/apiCallDetails'
import { installationsTariffs, durations } from '../../../assets/offlineData/FilterOptions'
import { clearTime } from '../../../services/utils/setTimeOut'
import { HierarchyContext } from '../../../context/HierarchyContext'

const Table = () => {
  const [searchRRNumber, setSearchRRNumber] = useState('')
  const [loading, setLoading] = useState(true)

  /// /////////////////////////////////////////////Filter States///////////////////////////////////////////
  const [selectedMR, setSelectedMR] = useState('')
  const [selectedReadingDay, setSelectedReadingDay] = useState('')

  const [tariff, setTariff] = useState([])
  const [duration, setDuration] = useState([])
  const [filterVisible, setFilterVisible] = useState(false)

  /// /////////////////////////////////////////////////////////////////////////////////////////////////////
  const [pageData, setPageData] = useState({
    currentPage: 1,
    nextPage: 1,
    previousPage: 1,
    lastPage: 1
  })
  const [installationData, setInstallationData] = useState([])
  const [installationsCount, setInstallationsCount] = useState('')

  const { auditMonthYear, mrCode } = useContext(AuditMonthContext)
  const notifier = useContext(NotifierContext)
  const { selectedHierarchy } = useContext(HierarchyContext)

  const filterHandler = (event) => {
    event.preventDefault()
    setFilterVisible(!filterVisible)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchVacantData(1)
    }, clearTime)
    return () => {
      clearTimeout(timer)
    }
  }, [searchRRNumber, auditMonthYear, selectedHierarchy])

  const fetchVacantData = async (pageNumber) => {
    try {
      setLoading(true)
      const response = await getOtherInstallations(
        auditMonthYear,
        pageNumber,
        searchRRNumber,
        'VACANT',
        selectedReadingDay,
        selectedMR,
        duration,
        tariff,
        [],
        '',
        selectedHierarchy
      )
      setInstallationData(response.data.installations)
      setInstallationsCount(response.data.count)
      const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
      setPageData({
        ...pageData,
        currentPage,
        nextPage,
        previousPage,
        lastPage
      })
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const searchByMrCodeAndReadingDay = () => {
    if (!selectedMR && !selectedReadingDay) {
      notifier.notifyError('Please select Mr code or Reading day')
      return
    }
    fetchVacantData(1)
  }

  const applyFilters = () => {
    fetchVacantData(1)
    setFilterVisible(false)
  }

  const resetFilter = async () => {
    try {
      setTariff([])
      setDuration([])
      setLoading(true)
      const response = await getOtherInstallations(
        auditMonthYear,
        1,
        searchRRNumber,
        'VACANT',
        selectedReadingDay,
        selectedMR,
        [],
        [],
        [],
        '',
        selectedHierarchy
      )
      setInstallationData(response.data.installations)
      setInstallationsCount(response.data.count)
      const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
      setPageData({
        ...pageData,
        currentPage,
        nextPage,
        previousPage,
        lastPage
      })
      setLoading(false)
      setFilterVisible(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const onClearMrCodeAndReadingDay = async () => {
    try {
      setSelectedMR('')
      setSelectedReadingDay('')
      setLoading(true)
      const response = await getOtherInstallations(
        auditMonthYear,
        1,
        searchRRNumber,
        'VACANT',
        '',
        '',
        duration,
        tariff,
        [],
        '',
        selectedHierarchy
      )
      setInstallationData(response.data.installations)
      setInstallationsCount(response.data.count)
      const { currentPage, nextPage, previousPage, lastPage } = response.data.pageData
      setPageData({
        ...pageData,
        currentPage,
        nextPage,
        previousPage,
        lastPage
      })
      setLoading(false)
    } catch (err) {
      notifier.notifyError(err)
      setLoading(false)
    }
  }

  const filterData = [
    {
      heading: 'Duration',
      shape: 'square',
      options: [...durations],
      value: duration,
      setValue: (selectedValue) => {
        duration.includes(selectedValue)
          ? setDuration(duration.filter((item) => item !== selectedValue))
          : setDuration([selectedValue])
      }
    },
    {
      heading: 'Tariff',
      shape: 'square',
      options: [...installationsTariffs],
      value: tariff,
      setValue: (selectedValue) => {
        tariff.includes(selectedValue)
          ? setTariff(tariff.filter((item) => item !== selectedValue))
          : setTariff([...tariff, selectedValue])
      }
    }
  ]

  return (
    <>
      <Filter
        footerStyle={{ position: 'relative' }}
        filterData={filterData}
        disableApply={!(tariff.length >= 1 || duration.length >= 1)}
        isFilterVisible={filterVisible}
        onFilterClose={filterHandler}
        onFiltersApply={() => applyFilters()}
        onResetApply={resetFilter}
      />
      <div className='col-md-9 mb-5'>
        <div className='row mt-35'>
          <div className='col-md-12'>
            <div className='comman-head'>
              <div className='installation-title'>
                <h1>Vacant</h1>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='main-tc-name-form mb-3'>
              <BillingTabSearch
                MRs={mrCode}
                selectedMR={selectedMR}
                setSelectedMR={setSelectedMR}
                showClearButton={!!(selectedMR || selectedReadingDay)}
                onClear={onClearMrCodeAndReadingDay}
                selectedReadingDay={selectedReadingDay}
                setSelectedReadingDay={setSelectedReadingDay}
                onSearch={() => searchByMrCodeAndReadingDay()}
              />
              <div className='row'>
                <div className='col-md-12'>
                  <div className='list-view-box'>
                    <div className='search-heading'>
                      <div className='digit-installation-title mb-3'>
                        <h3 className='mb-4 ms-0 font-medium-500'>
                          {installationsCount?.toLocaleString('en-IN')} Installations
                        </h3>
                      </div>
                      <div className='row'>
                        <div className='col-12 col-md-6'>
                          <div className='search-space'>
                            <input
                              type='text'
                              maxLength='25'
                              placeholder='RR Number'
                              value={searchRRNumber}
                              onChange={(e) => {
                                setSearchRRNumber(e.target.value)
                              }}
                              className='form-control search'
                            />{' '}
                          </div>
                        </div>
                        <div className='col-12 col-md-6 text-right'>
                          <div className='export-filter'>
                            {/* <a
                              href="#"
                              className="export-btn">
                              <img
                                className="fa img-fluid"
                                src={excelIcon}
                              />
                              &nbsp; <span>Export to Excel</span>
                            </a> */}
                            <button
                              onClick={filterHandler}
                              type='button'
                              className='btn btn-primary filter-btn'
                            >
                              <img
                                src={filterIcon}
                                alt='missing icon'
                                className=''
                                width='18'
                                height='12'
                              />
                              Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='table-responsive'>
                      <table className='table'>
                        <thead>
                          <tr>
                            <th
                              className='pl-15'
                              scope='col'
                            >
                              #
                            </th>
                            <th scope='col'>MR Code</th>
                            <th scope='col'>Reading Day</th>
                            <th scope='col'>Account ID</th>
                            <th scope='col'>RR Number</th>
                            <th scope='col'>Tariff</th>
                            <th scope='col'>Consumer Name</th>
                            <th scope='col'>Sanction Load</th>
                            <th scope='col'>Duration</th>
                            {/* <th scope="col">Arrears</th> */}
                            {/* <th scope="col">Remark</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {loading
                            ? (
                              <NoDataFound loading={loading} />
                              )
                            : (
                                installationData.length === 0 && (
                                  <NoDataFound message='No Installations Found!' />
                                )
                              )}
                          {!loading &&
                            installationData.map((installation, index) => {
                              const presentMonthData = installation?.audit.filter(
                                (data) => auditMonthYear === data?.month?.slice(0, 7)
                              )
                              const from = presentMonthData[0]
                                ? 'from' in presentMonthData[0]?.billing
                                : false
                              return (
                                <tr key={index}>
                                  <td
                                    className='pl-15'
                                    scope='row'
                                  >
                                    {(pageData.currentPage - 1) * perPageCount + (index + 1)}
                                  </td>
                                  <td>{installation.MR?.mrCode ?? '----'}</td>
                                  <td>{installation.readingDay || '----'}</td>
                                  <td>{installation.accountId ?? '----'}</td>
                                  <td>{installation.RRNumber ?? '----'}</td>
                                  <td>{installation.tariff?.short ?? '----'}</td>
                                  <Ellipsis>
                                    {'consumerName' in installation
                                      ? installation.consumerName
                                      : '-'}
                                  </Ellipsis>
                                  <td>
                                    {installation.sanctionedLoad.kW} KW,{' '}
                                    {installation?.sanctionedLoad?.hp ?? '--'} HP
                                  </td>
                                  <td>
                                    {' '}
                                    {`${
                                      from ? `${presentMonthData[0].billing?.from} Months` : '-'
                                    } `}
                                  </td>
                                  {/* <td>{reading ? reading : '-'}</td> */}
                                  {/* <td>{remark ? remarks.unBilled.map((value) =>
                                    remark == value.value && value.label
                                  ) : '-'}</td> */}
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav
          className='float-end'
          aria-label='Page navigation example'
        >
          <Paginator
            currentPage={pageData.currentPage}
            lastPage={pageData.lastPage}
            onPressNext={() => {
              fetchVacantData(pageData.currentPage + 1)
            }}
            onPressPrevious={() => {
              fetchVacantData(pageData.currentPage - 1)
            }}
          />
        </nav>
      </div>
    </>
  )
}

export default Table
