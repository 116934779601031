import React, { useState, useContext, useEffect } from 'react'
import UploadSection from '../../../../components/molecules/UploadSection/UploadSection'
import Button from '../../../../components/atoms/Button'
import styles from './tcTagging.module.scss'
import historyIcon from '../../../../assets/img/history.png'
import FileStatus from '../../../../components/molecules/FileStatus/FileStatus'
import SampleFileModal from '../../../../components/atoms/SampleFileModal/SampleFileModal'
import { NotifierContext } from '../../../../context/NotifierContext'
import { getPresignedURL, uploadFile, getSampleFilePresignedURL, getDownLoadProcessedResultUrl, getUploadStatus } from '../../../../services/api/tcAuditService'
import { DateToYYYYMM } from '../../../../services/utils/date'
import { useHistory } from 'react-router-dom'
import RefreshButton from '../../../../components/atoms/RefreshButton/RefreshButton'
import InfoModal from '../../../../components/atoms/Modal/Modal'
import { fetchUserDesignation } from '../../../../services/utils/meta'

// import LoadMoreButton from '../../../../components/atoms/LoadMoreButton/LoadMoreButton'

const TcTagging = () => {
  const [sampleFileOpen, setSampleFileOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [processedResultLoader, setProcessedResultLoader] = useState(false)
  const [sampleFileLoader, setSampleFileLoader] = useState(false)
  const { notifyError, notifySuccess } = useContext(NotifierContext)
  const [filUploadLoader, setFileUploadLoader] = useState(false)
  const [auditMonth, setAuditMonth] = useState(undefined)
  const [filesHistory, setFilesHistory] = useState([])
  const [modalVisible, setModalVisibility] = useState(false)

  // const [pageData, setPageData] = useState({
  //   current: 1,
  //   last: 1
  // })

  const history = useHistory()

  useEffect(() => {
    fetchFileUploadStatus(1)
  }, [])

  const loggedInUserDesignation = fetchUserDesignation()

  const fetchFileUploadStatus = async (page) => {
    try {
      setLoading(true)
      const response = await getUploadStatus({
        fileCategory: 'tcInstallationTag',
        page,
        perPage: 1,
        sortBy: 'uploadDate',
        ...(loggedInUserDesignation === 'ae' && { uploadedBy: 'AE' })

      })
      if (page === 1) {
        setFilesHistory(response.data.fileHistory)
      } else {
        setFilesHistory([...filesHistory, ...response.data.fileHistory])
      }
      // setPageData(response.data.pageData)
    } catch (error) {
      notifyError(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSampleFileDownload = async () => {
    try {
      setSampleFileLoader(true)
      const response = await getSampleFilePresignedURL('tcInstallationTag')
      setSampleFileOpen(false)
      notifySuccess('File download has started...')
      window.location.href = response.data
    } catch (err) {
      notifyError(err)
    } finally {
      setSampleFileLoader(false)
    }
  }

  const handleFileUploading = async (event) => {
    try {
      setFileUploadLoader(true)
      if (auditMonth === undefined) {
        throw new Error('Select the audit month for which you want to upload this file!')
      }

      const fileExtension = event.target.files[0].name.split('.').pop()

      if (fileExtension !== 'xlsx' && fileExtension !== 'xlsb' && fileExtension !== 'xls') {
        throw new Error('This type of file is not allowed to upload. You can upload only files which have xls,xlsx,xlsb extensions')
      }

      const auditMonthYear = DateToYYYYMM(auditMonth)
      const response = await getPresignedURL('tcInstallationTag', auditMonthYear)
      await uploadFile(event.target.files[0], response.data[fileExtension])
      notifySuccess('Successfully Uploaded the TC installation tagging file.')
      setAuditMonth(undefined)
    } catch (err) {
      notifyError(err)
    } finally {
      setFileUploadLoader(false)
    }
  }

  // const handleLoadMore = () => {
  //   const nextPage = pageData.current + 1
  //   fetchFileUploadStatus(nextPage)
  // }

  const handleProcessingResultDownload = async (fileId) => {
    try {
      setProcessedResultLoader(true)
      const response = await getDownLoadProcessedResultUrl('tcInstallationTag', fileId)
      window.location.href = response.data
    } catch (error) {
      notifyError(error)
    } finally {
      setProcessedResultLoader(false)
    }
  }

  return (
    <div className='container'>

      <UploadSection
        fileName='TC Installation tagging'
        hasSampleFile
        hasAuditMonth
        sampleFileHandler={() => setSampleFileOpen(true)}
        handleFileUpload={handleFileUploading}
        fileUploadDisabled={filUploadLoader}
        selectedDate={auditMonth}
        onChangeDate={setAuditMonth}
      />
      <div className='d-flex align-items-center justify-content-between mt-5 my-4'>
        <div className={styles.heading}>
          Latest file upload status (uploaded by me)
        </div>
        <RefreshButton
          onRefresh={() => { fetchFileUploadStatus(1) }}
          loading={loading}
        />

      </div>
      {loading
        ? <div>Loading....</div>
        : filesHistory.length === 0
          ? <div>No files uploaded!</div>
          : (
            <>
              {filesHistory.map((file, index) => {
                return (
                  <div className={styles.fileStatusContainer} key={file.fileId}>
                    <FileStatus
                      index={index + 1}
                      status={file.status}
                      fileId={file.fileId}
                      sectionResult={file.sectionResult}
                      uploadedOn={file.uploadedOn}
                      message={file.message}
                      onProcessingResultDownload={() => { handleProcessingResultDownload(file.fileId) }}
                      disableProcessedResult={processedResultLoader}
                      count={{
                        success: file.count?.success ?? '-',
                        failure: file.count?.failure ?? '-',
                        skipped: file.count?.skipped ?? '-'
                      }}
                      onClickFailureRows={() => setModalVisibility('failure')}
                      onClickSkippedRows={() => setModalVisibility('skipped')}
                      displaySectionResultButton={loggedInUserDesignation === 'cio'}
                    />
                  </div>
                )
              })}
              {/* {!(pageData.current === pageData.last) &&
                <LoadMoreButton onClick={handleLoadMore} />} */}
            </>
            )}

      <div className={`${styles['btn-container']} mt-5`}>
        <Button
          className={`btn btn-light ${styles['upload-history-btn']}`}
          onClick={() => {
            history.push('/file-upload?tab=tcRrTaggingFileHistory')
          }}
        >
          View file upload history
          <img src={historyIcon} alt={styles['history-icon']} />
        </Button>
      </div>
      <SampleFileModal
        okBtnDisabled={sampleFileLoader}
        open={sampleFileOpen}
        heading='Instructions - TC installation tagging'
        onClose={() => setSampleFileOpen(false)}
        onSubmit={handleSampleFileDownload}
      >
        <h6><b>*  Indicates mandatory columns</b></h6>
        {/* <br /> */}
        <h1>Instructions to be followed while filling these fields</h1>
        <h3>Account ID</h3>
        <div>1. This is the Account ID of the installation</div>
        <div>{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}<b>NOTE</b>: This is NOT to be confused with RR number</div>
        <div>2. Must be less than 30 characters</div>
        <div>3. Can contain alphabets, numbers, space and period (.)</div>
        <br />
        <h3>TC Number</h3>
        <div>1. TC number as given in the Create TC file</div>
        <div>2. Can only contain numerical values and can not exceed 10 digits</div>
        <br />
        <h3>Section</h3>
        <div>1. This should be the section to which the installation belongs</div>
      </SampleFileModal>
      <InfoModal
        title={`Possible reasons for ${modalVisible} rows`}
        visible={modalVisible}
        onModalClose={() => setModalVisibility(false)}
        hideFooter
      >
        {modalVisible === 'failure' &&
          (
            <>
              1. When installation with matching Account ID is not found in their O&M
              <br /><br />
              2. When TC with matching number is not found in their O&M
              <br /><br />
              3. When reading day of installation and TC do not match
              <br /><br />
              4. When MR tagged to installation is not tagged to TC
              <br /><br />
              5. When TC has already been audited for the current or future month
              <br /><br />
              6. When the TC to which the installation is already tagged has already been audited for the current or future month
              <br /><br />

            </>
          )}
        {modalVisible === 'skipped' &&
          <>
            1. Rows are skipped when the installation is already tagged to the specified TC for the specified audit month
            <br /> <br />
          </>}
      </InfoModal>
    </div>
  )
}

export default TcTagging
